import Button from '@components/atoms/Button';
import {toggleModal} from '@store/slices/ui';
import {GlobalContainer} from '@styles/pages/_app.styles';
import dynamic from 'next/dynamic';
import Image from 'next/legacy/image';
import {FC} from 'react';
import HtmlParser from 'react-html-parser';
import {useDispatch} from 'react-redux';
import {
  BannerWithBtnContainer,
  BannerWithBtnImg,
  BannerWithBtnInfo,
  BannerWithBtnText,
  BannerWithBtnTitle,
  BannerWithBtnWrapper,
  BannerWithoutBtn,
  BannerWithoutBtnContainer,
  BannerWithoutBtnImg,
  BannerWithoutBtnInfo,
  BannerWithoutBtnText,
  BannerWithoutBtnTitle,
} from './Banner.styles';

const MailingContent = dynamic(() => import('../MailingList/MailingContent'));

interface BannerProps {
  title: string;
  titleColor?: string;
  text: string;
  textColor?: string;
  btnText?: string;
  imgUrl: string;
  bgColor: string;
  btnStyle?: Record<string, string>;
  btnVariant?: 'outlined' | 'filled';
  imgWidth?: number;
  withBtn?: boolean;
  titleNoWrap?: boolean;
  centerImg?: boolean;
  btnOnClick?: () => void;
  btnLink?: string;
  subscribtionCode?: string;
  bannerHeight?: number | 'auto';
  imgHeight?: number;
  hiddenButton?: boolean;
  className?: string;
}

const Banner: FC<BannerProps> = ({
  title,
  titleColor,
  text,
  textColor,
  btnText = '',
  imgUrl,
  bgColor,
  btnStyle,
  btnVariant = 'filled',
  imgWidth,
  withBtn = true,
  titleNoWrap = false,
  centerImg = true,
  btnOnClick,
  btnLink,
  subscribtionCode,
  bannerHeight,
  imgHeight,
  hiddenButton = false,
  className,
}) => {
  const dispatch = useDispatch();

  function onModalClick() {
    dispatch(toggleModal());
  }

  return (
    <>
      {withBtn ? (
        <BannerWithBtnWrapper bgColor={bgColor} className={className}>
          <GlobalContainer>
            <BannerWithBtnContainer bannerHeight={bannerHeight}>
              <BannerWithBtnInfo>
                <BannerWithBtnTitle titleColor={titleColor!} titleNoWrap={titleNoWrap}>
                  {title}
                </BannerWithBtnTitle>
                <BannerWithBtnText textColor={textColor!}>
                  {HtmlParser(text)}
                </BannerWithBtnText>
                {!hiddenButton && (
                  <Button
                    title={btnText}
                    variant={btnVariant}
                    fontSize={16}
                    style={{padding: '16px 95.5px', ...btnStyle}}
                    onClick={subscribtionCode ? onModalClick : btnOnClick}
                    link={btnLink}
                    openLinkBlank
                  />
                )}
              </BannerWithBtnInfo>
              <BannerWithBtnImg imgWidth={imgWidth} centerImg={centerImg}>
                {imgUrl && (
                  <Image
                    src={imgUrl}
                    layout="intrinsic"
                    width={458}
                    height={imgHeight ?? 240}
                    objectFit="contain"
                    alt="Изображение банера"
                  />
                )}
              </BannerWithBtnImg>
            </BannerWithBtnContainer>
          </GlobalContainer>
          {subscribtionCode && <MailingContent subscribtionCode={subscribtionCode} />}
        </BannerWithBtnWrapper>
      ) : (
        <BannerWithoutBtn bgColor={bgColor}>
          <GlobalContainer>
            <BannerWithoutBtnContainer>
              <BannerWithoutBtnInfo>
                <BannerWithoutBtnTitle titleColor={titleColor!} titleNoWrap={titleNoWrap}>
                  {title}
                </BannerWithoutBtnTitle>
                <BannerWithoutBtnText textColor={textColor!}>
                  {HtmlParser(text)}
                </BannerWithoutBtnText>
              </BannerWithoutBtnInfo>
              <BannerWithoutBtnImg imgWidth={imgWidth} centerImg={centerImg}>
                {imgUrl && (
                  <Image
                    src={imgUrl}
                    layout="fill"
                    width={398}
                    height={223}
                    alt="Изображение банера"
                  />
                )}
              </BannerWithoutBtnImg>
            </BannerWithoutBtnContainer>
          </GlobalContainer>
        </BannerWithoutBtn>
      )}
    </>
  );
};

export default Banner;
