import {theme} from '@styles/theme';
import styled from 'styled-components';

interface StyledBannerProps {
  bgColor?: string;
  pbTop?: number;
  pbBtn?: number;
  titleNoWrap?: boolean;
  titleColor?: string;
  textColor?: string;
  imgWidth?: number | undefined;
  centerImg?: boolean;
}

export const BannerWithBtnWrapper = styled.div`
  background: ${({bgColor}: StyledBannerProps) => bgColor && `${bgColor}`};
`;

export const BannerWithBtnContainer = styled.div<{bannerHeight?: number | 'auto'}>`
  display: flex;
  justify-content: space-between;
  padding: ${({bannerHeight}) => bannerHeight === 'auto' && '38px 0'};
  height: ${({bannerHeight}) =>
    bannerHeight ? (bannerHeight === 'auto' ? 'auto' : `${bannerHeight}px`) : '331px'};

  @media (max-width: 1080px) {
    height: ${({bannerHeight}) => !bannerHeight && '331px'};
  }
  @media (max-width: 850px) {
    align-items: center;
  }
  @media (max-width: 682px) {
    flex-direction: column;
    height: 100%;
    padding: 50px 0;
  }
`;

export const BannerWithBtnInfo = styled.div`
  align-self: center;
  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media (max-width: 768px) {
    & button {
      width: 100% !important;
      padding: 15px 61.5px !important;
    }
    align-items: flex-start;
  }
  @media (max-width: 682px) {
    align-items: center;
  }
  @media (max-width: 470px) {
    & > button {
      padding: 15px 62.5px !important;
    }
  }
  @media (max-width: 420px) {
    width: 100%;
    align-items: flex-start;
    & > div:last-child {
      width: 100%;
    }
    & button {
      padding: 16px 0 !important;
      width: 100% !important;
    }
    & a {
      width: 100%;
    }
  }
`;

export const BannerWithBtnTitle = styled.div`
  font-family: ${theme.fonts.headline};
  font-size: 36px;
  line-height: 43px;
  font-weight: 400;
  margin-bottom: 20px;
  white-space: ${({titleNoWrap}: StyledBannerProps) =>
    titleNoWrap ? 'nowrap' : 'normal'};
  color: ${({titleColor}: StyledBannerProps) =>
    titleColor ? `${titleColor}` : `${theme.colors.darkText}`};
  @media (max-width: 910px) {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 10px;
  }
  @media (max-width: 682px) {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    margin-top: 30px;
  }
  @media (max-width: 420px) {
    text-align: left;
    letter-spacing: -0.25px;
  }
  @media (max-width: 410px) {
    text-align: left;
  }
  @media (max-width: 410px) {
    text-align: left;
  }
`;

export const BannerWithBtnText = styled.div`
  font-size: 18px;
  margin-bottom: 40px;
  color: ${({textColor}: StyledBannerProps) =>
    textColor ? `${textColor}` : `${theme.colors.gray}`};
  font-weight: 400;
  @media (max-width: 820px) {
    font-size: 16px;
    line-height: 19px;
  }
  @media (max-width: 520px) {
    width: 80%;
    text-align: center;
    font-size: 15px;
    line-height: 18px;
  }
  @media (max-width: 420px) {
    text-align: left;
    width: 100%;
  }
  & ul {
    padding-left: 0;
    list-style: inside;
  }
`;

export const BannerWithBtnImg = styled.div`
  width: ${({imgWidth}: StyledBannerProps) => (imgWidth ? `${imgWidth}%` : '45%')};
  font-size: 0;
  line-height: 0;
  align-self: ${({centerImg}) => (centerImg ? 'center' : 'flex-end')};
  display: flex;
  justify-content: center;
  img {
    display: block;
  }
  min-width: 45%;
  @media (max-width: 1050px) {
    width: 40%;
    align-self: center;
  }
  @media (max-width: 768px) {
    width: 50%;
  }
  @media (max-width: 768px) {
    width: 60%;
  }
  @media (max-width: 768px) {
    width: 60%;
  }
  @media (max-width: 682px) {
    order: -1;
    text-align: center;
    width: 50%;
    margin-left: 0;
  }
  @media (max-width: 470px) {
    width: 95%;
    margin: 0 auto;
  }
`;

export const BannerWithoutBtn = styled.div`
  background: ${({bgColor}: StyledBannerProps) => bgColor && `${bgColor}`};
`;

export const BannerWithoutBtnContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 383px;
  @media (max-width: 750px) {
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    justify-content: unset;
    height: 100%;
    padding: 50px 0;
  }
`;

export const BannerWithoutBtnInfo = styled.div`
  @media (max-width: 1050px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 750px) {
    align-items: center;
    margin-top: 30px;
  }
  @media (max-width: 470px) {
    text-align: left;
  }
`;

export const BannerWithoutBtnTitle = styled.div`
  font-family: ${theme.fonts.headline};
  font-size: 36px;
  line-height: 43px;
  font-weight: 400;
  white-space: ${({titleNoWrap}: StyledBannerProps) =>
    titleNoWrap ? 'nowrap' : 'normal'};
  color: ${({titleColor}: StyledBannerProps) =>
    titleColor ? `${titleColor}` : `${theme.colors.darkText}`};
  @media (max-width: 950px) {
    font-size: 24px;
    line-height: 29px;
  }
  @media (max-width: 560px) {
    font-size: 20px;
    line-height: 24px;
  }
  @media (max-width: 470px) {
    width: 100%;
  }
  @media (max-width: 682px) {
    font-size: 20px;
    text-align: center;
  }
  @media (max-width: 410px) {
    text-align: left;
  }
  @media (max-width: 682px) {
    font-size: 20px;
    text-align: center;
  }
  @media (max-width: 410px) {
    text-align: left;
  }
`;

export const BannerWithoutBtnText = styled.div`
  font-size: 18px;
  margin-top: 20px;
  font-weight: 400;
  color: ${({textColor}: StyledBannerProps) =>
    textColor ? `${textColor}` : `${theme.colors.gray}`};
  width: 81%;
  line-height: 21px;
  @media (max-width: 950px) {
    font-size: 16px;
    line-height: 19px;
    width: 91%;
  }
  @media (max-width: 560px) {
    text-align: center;
    font-size: 15px;
    line-height: 18px;
  }
  @media (max-width: 470px) {
    width: 100%;
    text-align: left;
  }
`;

export const BannerWithoutBtnImg = styled.div`
  width: ${({imgWidth}: StyledBannerProps) => (imgWidth ? `${imgWidth}%` : '55%')};
  height: 223px;
  font-size: 0;
  line-height: 0;
  display: flex;
  align-items: center;
  position: relative;
  display: flex;
  justify-content: center;
  img {
    display: block;
  }
  @media (max-width: 1100px) {
    width: 80%;
  }
  @media (max-width: 750px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: 430px) {
    align-items: flex-end;
    height: 164px;
  }
`;
