import {useRouter} from 'next/router';
import {useEffect, useRef, useState} from 'react';
import useLocalStorage from './useLocalStorage';
import {IPageBannerData} from '@api/types/Banners';
import parseJSON from '@utils/parseJSON';

const useBannerSwitcher = (
  banners: IPageBannerData[] | null | undefined,
  pageID: string | number | null | undefined,
) => {
  const router = useRouter();

  const firstRender = useRef(true);
  const [updatingBanner, setUpdatingBanner] = useState<boolean>(false);
  const [bannerId, setBannerId] = useLocalStorage('banner', {});

  useEffect(() => {
    if ((firstRender.current || updatingBanner) && banners && pageID) {
      firstRender.current = false;

      const currentIndex = bannerId[pageID];

      const nextValue = pageID in bannerId ? currentIndex + 1 : 0;
      const actualValue = nextValue >= (banners?.length || 0) ? 0 : nextValue;

      const oldState = parseJSON(localStorage.getItem('banner'));

      setBannerId({
        ...(oldState && typeof oldState === 'object' ? oldState : {}),
        [pageID]: actualValue,
      });
    }

    router.events.on('routeChangeStart', () => setUpdatingBanner(true));

    return () => router.events.off('routeChangeStart', () => setUpdatingBanner(false));
  }, [router]);

  return !firstRender.current && banners && pageID ? banners[bannerId[pageID]] : null;
};

export default useBannerSwitcher;
